export const environment = {
  production: false,
  name: 'qa',

  modeDev: false,
  urlLambda: 'https://apiqa.azurianhealth.com/front/seguimiento/webresources/',
  urlLink: 'https://apiqa.azurianhealth.com/link/',
  urlUploadLambda: "",
  urlSocket: 'https://apiqa.azurianhealth.com/front',
  
  urlKeycloak: 'https://ssoqa.azurianhealth.com',
  azhUri: 'https://qa.azurianhealth.com',
  realm: 'az-health',
  clientId: 'health-front',

  S3_DIR_IMG_MANTENEDOR_CATEGORIADISPOSITIVOS: 'Mantenedor_Categoria_Dispositivos',
  S3_DIR_IMG_MANTENEDOR_CUENTAS: 'Mantenedor_Cuentas',
  S3_DIR_IMG_EMAIL_ADJUNTOS: 'Email_Adjuntos',
  S3_DIR_MINIATURA_VIDEOMONITOREO: 'Video_Monitoreo_Miniatura',
  S3_DIR_IMG_CONFIGURACIONES: 'Configuraciones',
  S3_DIR_IMG_TEMPLATE_RESOURCES: 'Template_Resources',

  URL_APP_ANDROID: 'https://play.google.com/store/apps/details?id=com.azurian.azurianhealth&hl=es_PE&gl=US',
  URL_APP_IOS: 'https://apps.apple.com/cl/app/azurian-health/id1599523831',

  DEFAULT_ASSETS: [
    'https://qa.azurianhealth.com/assets/img/logo.png',
  ],
  ETIQUETAS_SISTEMA: [
    { id: 1, value: 'Ingreso' },
    { id: 2, value: 'Egreso' },
  ],
  ETIQUETAS_POR_DEFECTO_GRUPO: [
    { id: 1, value: 'Ingreso' },
    { id: 2, value: 'Egreso' },
  ],

  TEMPLATES_POR_DEFECTO_GRUPO: [{ id: 0, value: 'POR DEFINIR' }],
  TEMPLATE_EMAIL: '',
  BASE_TEMPLATE:
    '<mjml><mj-body><mj-section><mj-column width="100%"></mj-column></mj-section></mj-body></mjml>',
  DATATABLES_LANGUAGE: {
    processing: 'Procesando...',
    search: '',
    lengthMenu: 'Mostrar _MENU_ registros',
    info: 'Mostrando del _START_ al _END_ de _TOTAL_ registros',
    infoEmpty: '',
    infoFiltered: '(filtrado de _MAX_ registros)',
    infoPostFix: '',
    loadingRecords: 'Cargando registros...',
    zeroRecords: 'No se encontraron registros',
    emptyTable: 'No hay registros disponibles',
    searchPlaceholder: 'Buscar...',
    paginate: {
      first: 'Primero',
      previous:
        '<i class="material-icons mdl-list__item-icon">chevron_left</i>',
      next: '<i class="material-icons mdl-list__item-icon">chevron_right</i>',
      last: 'Último',
    },
    select: {
      rows: {
        _: '%d Seleccionados',
        0: '',
        1: '1 Seleccionado',
      },
    },
    aria: {
      sortAscending: ': Activar para ordenar la tabla en orden ascendente',
      sortDescending: ': Activar para ordenar la tabla en orden descendente',
    },
    buttons: {
      selectAll: 'Todos',
      selectNone: 'Ninguno',
    },
  },
  DATATABLES_DEFAULT_DOM:
    '<"mylength mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone"l>t' +
    '<"mdl-grid table-pag"<"mdl-cell mdl-cell--4-col mdl-cell--4-col-tablet mdl-cell--6-col-phone"i><"mdl-cell mdl-cell--8-col mdl-cell--4-col-tablet mdl-cell--6-col-phone"p><"hidden" B>>',
};
